//https://dev.to/vetswhocode/build-a-crud-firestore-app-in-react-gatsby-with-hooks-4ig9

import React from "react"
import styles from "./form.module.css"
import firebase from "../../Database"
import { getCurrentUser } from "../../../utils/auth"

class RoiForm extends React.Component {

  state = {
    user: ``,
    value_bucket: ``,
    feature: ``,
    schedule_rate: ``,
    missed_calls_week: ``,
    value_of_patient: ``,
    results_appointments: ``,
    results_revenue: ``,
  }

  componentDidMount() {
    const { profileObj } = getCurrentUser()
    this.setState({ user: profileObj.name, schedule_rate: 40, value_bucket: "New Customer Growth", feature: "Missed Call Text" })
  }

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    firebase
      .firestore()
      .collection("roi")
      .add({
        user: this.state.user,
        value_bucket: this.state.value_bucket,
        feature: this.state.feature,
        schedule_rate: this.state.schedule_rate,
        missed_calls_week: this.state.missed_calls_week,
        value_of_patient: this.state.value_of_patient
      }).catch(err => {
        console.warn({ err })
      })
      .then(ref => {
        console.info("Added document with ID: ", ref.id)
      })
  }

  render() {
    return (
      <form
        className={styles.form}
        method="post"
        onSubmit={event => { this.handleSubmit(event) }}
      >
        <p className={styles[`form__instructions`]}>

        </p>
        <label className={styles[`form__label`]}>
          Value Bucket
          <select name="value_bucket" className={styles[`form__input`]} onBlur={this.handleUpdate}>
            <option value="New Customer Growth">New Customer Growth</option>
            <option value="Customer Experience">Customer Experience</option>
            <option value="Office Efficiency">Office Efficiency</option>
            <option value="Opportunity">Revenue Opportunity</option>
          </select>
          {/* <input
            className={styles[`form__input`]}
            type="text"
            name="username"
            onChange={this.handleUpdate}
          /> */}
        </label>
        <label className={styles[`form__label`]}>
          Feature
          <select name="feature" className={styles[`form__input`]} onBlur={this.handleUpdate}>
            <option value="Missed Call Text">Missed Call Text</option>
          </select>
        </label>
        <label className={styles[`form__label`]}>
          Average Weave Schedule Rate %
          <input
            className={styles[`form__input`]}
            type="text"
            name="schedule_rate"
            onChange={this.handleUpdate}
            value={this.state.schedule_rate}
          />
        </label>
        <label className={styles[`form__label`]}>
          Number of Missed Calls/Week
          <input
            className={styles[`form__input`]}
            type="text"
            name="missed_calls_week"
            onChange={this.handleUpdate}
            value={this.state.missed_calls_week}
          />
        </label>
        <label className={styles[`form__label`]}>
          Average Value of Patient $
          <input
            className={styles[`form__input`]}
            type="text"
            name="value_of_patient"
            onChange={this.handleUpdate}
            value={this.state.value_of_patient}
          />
        </label>
        <input className={styles[`form__button`]} type="submit" value="Calculate" />
      </form>
    )
  }
}

export default RoiForm
