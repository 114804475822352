import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/Layout"
import EfficiencyCalculator from "../components/Efficiency"
import Roi from "../components/Roi"
import Login from "../components/Login"
import PrivateRoute from "../components/PrivateRoute"
import Status from "../components/Status"
import Blobs from '../components/Blobs'

const App = () => (
  <Layout>
    <Status />
    <Router>
      <PrivateRoute path="/app/roi" component={Roi} />
      <PrivateRoute path="/app/efficiency" component={EfficiencyCalculator} />
      <Login path="/app/login" />
    </Router>
    <Blobs />
  </Layout>
)

export default App
