import firebase from 'firebase/app'
import 'firebase/firestore'

// Sales tools firebase config.
var firebaseConfig = {
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.GATSBY_FIREBASE_DB_URL,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
    storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.GATSBY_FIREBASE_APP_ID
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)


export default firebase
