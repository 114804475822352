import React, { useState, useEffect } from "react"
import View from "./View"
import RoiForm from "./Forms/Roi"
import firebase from "./Database"

const useItems = () => {
  const [items, setItems] = useState([])
  useEffect(() => {
    //added variable unsubscribe
    const unsubscribe = firebase
      .firestore()
      .collection("roi")
      .onSnapshot(snapshot => {
        const listItems = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }))
        setItems(listItems)
      })
    //called the unsubscribe--closing connection to Firestore.
    return () => unsubscribe()
  }, [])
  return items
}

//render() {
const Roi = () => {
  //const { profileObj } = getCurrentUser()
  const roiData = useItems();
  //console.warn({ roiData }) // DO NOT CONSOLE LOG HERE, IT WILL CRASH YOUR BROWSER! IS THERE SOMETHING GOING ON WITH OPEN CONNECTIONS?

  return (
    <>
      <View title="ROI Calculator">
        {/* <p>Welcome back, {profileObj.name}! Form goes here?</p> */}
        <RoiForm />
        <table>
          <thead>
            <tr>

              <th>User</th>
              <th>Value Bucket</th>
              <th>Feature</th>
              <th>Missed Calls/Week</th>
              <th>Schedule Rate</th>
              <th>Value of Patients</th>
            </tr>

          </thead>
          {roiData.map(item => (
            <tbody key={item.id}>
              <tr>
                <td>{item.user}</td>
                <td>{item.value_bucket}</td>
                <td>{item.feature}</td>
                <td>{item.missed_calls_week}</td>
                <td>{item.schedule_rate}</td>
                <td>{item.value_of_patient}</td>
              </tr>
            </tbody>
          ))}
        </table>
      </View>
      {/* <RoiForm
          handleUpdate={e => this.handleUpdate(e)}
          handleSubmit={e => this.handleSubmit(e)}
        /> */}
    </>
  )
}

export default Roi
